import { Button } from "@components/buttons/button.component";
import { ErrorState } from "@components/states/error-state/error-state.component";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import { CreateReminderDTO, useCreateReminder } from "@utils/mutations/reminders/use-create-reminder";
import { useForm } from "@utils/validations/use-form";
import * as yup from "yup";

const CreateReminder = () => {
	const { mutate, error, isSuccess } = useCreateReminder();

	const form = useForm<CreateReminderDTO>({
		onSubmit: async ({ value }) => mutate(value),
	});

	if (isSuccess) {
		return <Navigate to="/dashboard/reminders" />;
	}

	return (
		<div>
			<form.Field name="text" validators={{ onChange: yup.string().min(3).max(600).required() }}>
				{(field) => (
					<textarea
						className="mt-2 w-full rounded bg-white bg-opacity-20 px-4 py-3 text-sm font-normal text-white text-opacity-50"
						onBlur={field.handleBlur}
						onChange={(e) => field.handleChange(e.target.value)}
						rows={30}
					/>
				)}
			</form.Field>

			{error && <ErrorState errors={error} />}

			<form.Subscribe selector={(state) => [state.canSubmit]}>
				{([canSubmit]) => <Button disabled={!canSubmit} onClick={() => form.handleSubmit()} type="submit" value="create reminder" />}
			</form.Subscribe>
		</div>
	);
};

export const Route = createFileRoute("/dashboard/reminders/create/")({
	component: CreateReminder,
});
