import { cn } from "@utils/helpers/class-name";
import { useSet } from "react-use";

interface StatusListFilterProps {
	items: Array<string>;
}

export const StatusListFilter = ({ items }: StatusListFilterProps) => {
	const [, { has, toggle }] = useSet<number>(new Set([]));

	return (
		<div className="flex">
			{items.map((item, index) => (
				<div
					className={cn(
						"cursor-pointer pr-4 text-lg font-normal text-white",

						index > 0 && "pl-4",
						has(index) && "text-blue-active",
						index != items.length - 1 && "border-r-2 border-r-white border-opacity-20",
					)}
					key={index}
					onClick={() => toggle(index)}>
					{item}
				</div>
			))}
		</div>
	);
};
