import { cn } from "@utils/helpers/class-name";
import { HeadingProps } from "react-html-props";

interface PageTitleProps extends HeadingProps {
	title: string;
}

export const PageTitle = ({ title, className }: PageTitleProps) => {
	return <h1 className={cn("text-6xl font-semibold text-white", className)}>{title}</h1>;
};
