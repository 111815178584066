import { Button } from "@components/buttons/button.component";
import { UsersRolesSelect } from "@components/selects/users-roles-select/users-roles-select.component";
import { ErrorState } from "@components/states/error-state/error-state.component";
import { FormProps } from "@interfaces/form-props";
import { User } from "@models/user";
import { CreateUserDTO } from "@utils/mutations/users/use-create-user";
import { useForm } from "@utils/validations/use-form";
import * as yup from "yup";

export const CreateUserForm = ({ onSubmit, errors }: FormProps<CreateUserDTO, User>) => {
	const form = useForm<CreateUserDTO>({
		onSubmit: async ({ value }) => onSubmit(value),
		validators: {
			onChange: yup.object({
				role: yup.object().required(),
				name: yup.string().max(50).required(),
				email: yup.string().email().max(50).required(),
			}),
		},
	});

	return (
		<div>
			<form.Field name="name">
				{(field) => (
					<input
						className="mt-2 h-10 w-full rounded bg-white bg-opacity-20 px-4 py-3 text-sm font-normal text-white text-opacity-50"
						onBlur={field.handleBlur}
						onChange={(e) => field.handleChange(e.target.value)}
						placeholder="Name"
					/>
				)}
			</form.Field>

			<form.Field name="email">
				{(field) => (
					<input
						className="mt-2 h-10 w-full rounded bg-white bg-opacity-20 px-4 py-3 text-sm font-normal text-white text-opacity-50"
						onBlur={field.handleBlur}
						onChange={(e) => field.handleChange(e.target.value)}
						placeholder="Email"
						type="email"
					/>
				)}
			</form.Field>

			<form.Field name="role">
				{(field) => (
					<UsersRolesSelect
						onBlur={field.handleBlur}
						onChange={(option) => option && field.handleChange(option)}
						value={field.state.value}
					/>
				)}
			</form.Field>

			{!!errors.length && <ErrorState errors={errors} />}

			<form.Subscribe selector={(state) => [state.isPristine || !state.canSubmit, state.isSubmitting]}>
				{([disabled]) => <Button disabled={disabled} onClick={() => form.handleSubmit()} type="submit" value="create user" />}
			</form.Subscribe>
		</div>
	);
};
