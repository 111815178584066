import { cn } from "@utils/helpers/class-name";
import { ButtonProps as BtnProps } from "react-html-props";

interface ButtonProps extends BtnProps {
	icon?: React.JSX.Element;
}

export const Button = ({ icon, ...props }: ButtonProps) => {
	const { value, disabled } = props;

	const classNames = cn(
		"mt-9 flex w-full items-center justify-between rounded-full bg-black px-5 py-3.5 text-sm text-white",
		disabled && "cursor-not-allowed opacity-30",
	);

	return (
		<button className={classNames} type="button" {...props}>
			<span className="text-sm">{value}</span>

			{icon}
		</button>
	);
};
