import { Playlist } from '@models/playlist';
import { useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToInstance } from 'class-transformer';

export const usePlaylists = () => {
	return useQuery({
		queryKey: ['playlists'],
		queryFn: async () => {
			return axios.get<Array<Playlist>>(`/playlists`).then(({ data }) => {
				return plainToInstance(Playlist, data);
			});
		},
	});
};
