import { FloatingActionButton } from "@components/buttons/floating-action-button/floating-action-button.component";
import { ThrashButton } from "@components/buttons/thrash-button/thrash-button.component";
import { SearchInput } from "@components/inputs/search-input/search-input.component";
import { PageTitle } from "@components/page-title/page-title.component";
import { PlaylistCard } from "@components/playlist-card/playlist-card.component";
import { createFileRoute } from "@tanstack/react-router";
import { usePlaylists } from "@utils/querys/playlists/use-playlists";

const MediaList = () => {
	const { data: playlists, isSuccess } = usePlaylists();

	return (
		<div>
			<FloatingActionButton to="create" />
			<div className="flex items-center justify-between">
				<PageTitle title="Playlists" />
				<SearchInput onSearch={() => {}} placeholder="Search playlists" />
			</div>
			<div className="mb-9 mt-2 flex items-center justify-end pl-1">
				<ThrashButton onClick={() => {}} />
			</div>

			<div className="flex">{isSuccess && playlists.map((playlist) => <PlaylistCard key={playlist.uuid} playlist={playlist} />)}</div>
		</div>
	);
};

export const Route = createFileRoute("/dashboard/media/")({
	component: MediaList,
});
