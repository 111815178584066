import { useContext, useState, type PropsWithChildren } from "react";

import { Session } from "@models/session";
import { clearStorage, getTokenFromStorage, setTokenInStorage } from "@utils/helpers/manage-storage";
import { setSentryUser } from "@utils/helpers/set-sentry-user";
import { plainToInstance } from "class-transformer";
import { useCallback, useEffect } from "react";
import { SessionContext } from "./session.context";

export const useStorageState = () => {
	const [token, internalSetToken] = useState<string>(getTokenFromStorage());

	useEffect(() => {
		const token = getTokenFromStorage();

		if (token) {
			internalSetToken(token);
		}
	}, [internalSetToken]);

	const setToken = useCallback(
		(token: string) => {
			internalSetToken(token);
			setTokenInStorage(token);
		},
		[internalSetToken],
	);

	return { token, setToken };
};

export const useSession = (): Session => {
	const context = useContext(SessionContext);

	if (!context) {
		throw new Error("useSession must be wrapped in a <SessionProvider />");
	}

	return context;
};

export const SessionProvider = ({ children }: PropsWithChildren) => {
	const { token, setToken } = useStorageState();

	const session = plainToInstance(Session, {
		token,

		startSession: (token: string) => {
			setToken(token);
		},

		clearSession: () => {
			setToken("");
			clearStorage();
			setSentryUser();
		},
	});

	return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
};
