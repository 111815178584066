/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './../pages/__root'
import { Route as LoginImport } from './../pages/login'
import { Route as DashboardRouteImport } from './../pages/dashboard/route'
import { Route as IndexImport } from './../pages/index'
import { Route as DashboardWelcomeIndexImport } from './../pages/dashboard/welcome/index'
import { Route as DashboardUsersIndexImport } from './../pages/dashboard/users/index'
import { Route as DashboardStudyIndexImport } from './../pages/dashboard/study/index'
import { Route as DashboardRemindersIndexImport } from './../pages/dashboard/reminders/index'
import { Route as DashboardQuotesIndexImport } from './../pages/dashboard/quotes/index'
import { Route as DashboardMediaIndexImport } from './../pages/dashboard/media/index'
import { Route as DashboardUsersCreateIndexImport } from './../pages/dashboard/users/create/index'
import { Route as DashboardUsersUuidIndexImport } from './../pages/dashboard/users/$uuid/index'
import { Route as DashboardRemindersCreateIndexImport } from './../pages/dashboard/reminders/create/index'
import { Route as DashboardRemindersUuidIndexImport } from './../pages/dashboard/reminders/$uuid/index'
import { Route as DashboardQuotesCreateIndexImport } from './../pages/dashboard/quotes/create/index'
import { Route as DashboardQuotesUuidIndexImport } from './../pages/dashboard/quotes/$uuid/index'
import { Route as DashboardStudyQuickInsightsTagsIndexImport } from './../pages/dashboard/study/quick-insights/tags/index'
import { Route as DashboardStudyQuickInsightsGroupsIndexImport } from './../pages/dashboard/study/quick-insights/groups/index'
import { Route as DashboardStudyQuickInsightsCreateIndexImport } from './../pages/dashboard/study/quick-insights/create/index'
import { Route as DashboardStudyQuickInsightsCategoriesIndexImport } from './../pages/dashboard/study/quick-insights/categories/index'
import { Route as DashboardStudyQuickInsightsTagsCreateIndexImport } from './../pages/dashboard/study/quick-insights/tags/create/index'
import { Route as DashboardStudyQuickInsightsGroupsCreateIndexImport } from './../pages/dashboard/study/quick-insights/groups/create/index'
import { Route as DashboardStudyQuickInsightsCategoriesCreateIndexImport } from './../pages/dashboard/study/quick-insights/categories/create/index'
import { Route as DashboardStudyQuickInsightsUuidPreviewIndexImport } from './../pages/dashboard/study/quick-insights/$uuid/preview/index'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRouteRoute = DashboardRouteImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const DashboardWelcomeIndexRoute = DashboardWelcomeIndexImport.update({
  id: '/welcome/',
  path: '/welcome/',
  getParentRoute: () => DashboardRouteRoute,
} as any)

const DashboardUsersIndexRoute = DashboardUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => DashboardRouteRoute,
} as any)

const DashboardStudyIndexRoute = DashboardStudyIndexImport.update({
  id: '/study/',
  path: '/study/',
  getParentRoute: () => DashboardRouteRoute,
} as any)

const DashboardRemindersIndexRoute = DashboardRemindersIndexImport.update({
  id: '/reminders/',
  path: '/reminders/',
  getParentRoute: () => DashboardRouteRoute,
} as any)

const DashboardQuotesIndexRoute = DashboardQuotesIndexImport.update({
  id: '/quotes/',
  path: '/quotes/',
  getParentRoute: () => DashboardRouteRoute,
} as any)

const DashboardMediaIndexRoute = DashboardMediaIndexImport.update({
  id: '/media/',
  path: '/media/',
  getParentRoute: () => DashboardRouteRoute,
} as any)

const DashboardUsersCreateIndexRoute = DashboardUsersCreateIndexImport.update({
  id: '/users/create/',
  path: '/users/create/',
  getParentRoute: () => DashboardRouteRoute,
} as any)

const DashboardUsersUuidIndexRoute = DashboardUsersUuidIndexImport.update({
  id: '/users/$uuid/',
  path: '/users/$uuid/',
  getParentRoute: () => DashboardRouteRoute,
} as any)

const DashboardRemindersCreateIndexRoute =
  DashboardRemindersCreateIndexImport.update({
    id: '/reminders/create/',
    path: '/reminders/create/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

const DashboardRemindersUuidIndexRoute =
  DashboardRemindersUuidIndexImport.update({
    id: '/reminders/$uuid/',
    path: '/reminders/$uuid/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

const DashboardQuotesCreateIndexRoute = DashboardQuotesCreateIndexImport.update(
  {
    id: '/quotes/create/',
    path: '/quotes/create/',
    getParentRoute: () => DashboardRouteRoute,
  } as any,
)

const DashboardQuotesUuidIndexRoute = DashboardQuotesUuidIndexImport.update({
  id: '/quotes/$uuid/',
  path: '/quotes/$uuid/',
  getParentRoute: () => DashboardRouteRoute,
} as any)

const DashboardStudyQuickInsightsTagsIndexRoute =
  DashboardStudyQuickInsightsTagsIndexImport.update({
    id: '/study/quick-insights/tags/',
    path: '/study/quick-insights/tags/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

const DashboardStudyQuickInsightsGroupsIndexRoute =
  DashboardStudyQuickInsightsGroupsIndexImport.update({
    id: '/study/quick-insights/groups/',
    path: '/study/quick-insights/groups/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

const DashboardStudyQuickInsightsCreateIndexRoute =
  DashboardStudyQuickInsightsCreateIndexImport.update({
    id: '/study/quick-insights/create/',
    path: '/study/quick-insights/create/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

const DashboardStudyQuickInsightsCategoriesIndexRoute =
  DashboardStudyQuickInsightsCategoriesIndexImport.update({
    id: '/study/quick-insights/categories/',
    path: '/study/quick-insights/categories/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

const DashboardStudyQuickInsightsTagsCreateIndexRoute =
  DashboardStudyQuickInsightsTagsCreateIndexImport.update({
    id: '/study/quick-insights/tags/create/',
    path: '/study/quick-insights/tags/create/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

const DashboardStudyQuickInsightsGroupsCreateIndexRoute =
  DashboardStudyQuickInsightsGroupsCreateIndexImport.update({
    id: '/study/quick-insights/groups/create/',
    path: '/study/quick-insights/groups/create/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

const DashboardStudyQuickInsightsCategoriesCreateIndexRoute =
  DashboardStudyQuickInsightsCategoriesCreateIndexImport.update({
    id: '/study/quick-insights/categories/create/',
    path: '/study/quick-insights/categories/create/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

const DashboardStudyQuickInsightsUuidPreviewIndexRoute =
  DashboardStudyQuickInsightsUuidPreviewIndexImport.update({
    id: '/study/quick-insights/$uuid/preview/',
    path: '/study/quick-insights/$uuid/preview/',
    getParentRoute: () => DashboardRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardRouteImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/media/': {
      id: '/dashboard/media/'
      path: '/media'
      fullPath: '/dashboard/media'
      preLoaderRoute: typeof DashboardMediaIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/quotes/': {
      id: '/dashboard/quotes/'
      path: '/quotes'
      fullPath: '/dashboard/quotes'
      preLoaderRoute: typeof DashboardQuotesIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/reminders/': {
      id: '/dashboard/reminders/'
      path: '/reminders'
      fullPath: '/dashboard/reminders'
      preLoaderRoute: typeof DashboardRemindersIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/study/': {
      id: '/dashboard/study/'
      path: '/study'
      fullPath: '/dashboard/study'
      preLoaderRoute: typeof DashboardStudyIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/users/': {
      id: '/dashboard/users/'
      path: '/users'
      fullPath: '/dashboard/users'
      preLoaderRoute: typeof DashboardUsersIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/welcome/': {
      id: '/dashboard/welcome/'
      path: '/welcome'
      fullPath: '/dashboard/welcome'
      preLoaderRoute: typeof DashboardWelcomeIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/quotes/$uuid/': {
      id: '/dashboard/quotes/$uuid/'
      path: '/quotes/$uuid'
      fullPath: '/dashboard/quotes/$uuid'
      preLoaderRoute: typeof DashboardQuotesUuidIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/quotes/create/': {
      id: '/dashboard/quotes/create/'
      path: '/quotes/create'
      fullPath: '/dashboard/quotes/create'
      preLoaderRoute: typeof DashboardQuotesCreateIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/reminders/$uuid/': {
      id: '/dashboard/reminders/$uuid/'
      path: '/reminders/$uuid'
      fullPath: '/dashboard/reminders/$uuid'
      preLoaderRoute: typeof DashboardRemindersUuidIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/reminders/create/': {
      id: '/dashboard/reminders/create/'
      path: '/reminders/create'
      fullPath: '/dashboard/reminders/create'
      preLoaderRoute: typeof DashboardRemindersCreateIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/users/$uuid/': {
      id: '/dashboard/users/$uuid/'
      path: '/users/$uuid'
      fullPath: '/dashboard/users/$uuid'
      preLoaderRoute: typeof DashboardUsersUuidIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/users/create/': {
      id: '/dashboard/users/create/'
      path: '/users/create'
      fullPath: '/dashboard/users/create'
      preLoaderRoute: typeof DashboardUsersCreateIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/study/quick-insights/categories/': {
      id: '/dashboard/study/quick-insights/categories/'
      path: '/study/quick-insights/categories'
      fullPath: '/dashboard/study/quick-insights/categories'
      preLoaderRoute: typeof DashboardStudyQuickInsightsCategoriesIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/study/quick-insights/create/': {
      id: '/dashboard/study/quick-insights/create/'
      path: '/study/quick-insights/create'
      fullPath: '/dashboard/study/quick-insights/create'
      preLoaderRoute: typeof DashboardStudyQuickInsightsCreateIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/study/quick-insights/groups/': {
      id: '/dashboard/study/quick-insights/groups/'
      path: '/study/quick-insights/groups'
      fullPath: '/dashboard/study/quick-insights/groups'
      preLoaderRoute: typeof DashboardStudyQuickInsightsGroupsIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/study/quick-insights/tags/': {
      id: '/dashboard/study/quick-insights/tags/'
      path: '/study/quick-insights/tags'
      fullPath: '/dashboard/study/quick-insights/tags'
      preLoaderRoute: typeof DashboardStudyQuickInsightsTagsIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/study/quick-insights/$uuid/preview/': {
      id: '/dashboard/study/quick-insights/$uuid/preview/'
      path: '/study/quick-insights/$uuid/preview'
      fullPath: '/dashboard/study/quick-insights/$uuid/preview'
      preLoaderRoute: typeof DashboardStudyQuickInsightsUuidPreviewIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/study/quick-insights/categories/create/': {
      id: '/dashboard/study/quick-insights/categories/create/'
      path: '/study/quick-insights/categories/create'
      fullPath: '/dashboard/study/quick-insights/categories/create'
      preLoaderRoute: typeof DashboardStudyQuickInsightsCategoriesCreateIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/study/quick-insights/groups/create/': {
      id: '/dashboard/study/quick-insights/groups/create/'
      path: '/study/quick-insights/groups/create'
      fullPath: '/dashboard/study/quick-insights/groups/create'
      preLoaderRoute: typeof DashboardStudyQuickInsightsGroupsCreateIndexImport
      parentRoute: typeof DashboardRouteImport
    }
    '/dashboard/study/quick-insights/tags/create/': {
      id: '/dashboard/study/quick-insights/tags/create/'
      path: '/study/quick-insights/tags/create'
      fullPath: '/dashboard/study/quick-insights/tags/create'
      preLoaderRoute: typeof DashboardStudyQuickInsightsTagsCreateIndexImport
      parentRoute: typeof DashboardRouteImport
    }
  }
}

// Create and export the route tree

interface DashboardRouteRouteChildren {
  DashboardMediaIndexRoute: typeof DashboardMediaIndexRoute
  DashboardQuotesIndexRoute: typeof DashboardQuotesIndexRoute
  DashboardRemindersIndexRoute: typeof DashboardRemindersIndexRoute
  DashboardStudyIndexRoute: typeof DashboardStudyIndexRoute
  DashboardUsersIndexRoute: typeof DashboardUsersIndexRoute
  DashboardWelcomeIndexRoute: typeof DashboardWelcomeIndexRoute
  DashboardQuotesUuidIndexRoute: typeof DashboardQuotesUuidIndexRoute
  DashboardQuotesCreateIndexRoute: typeof DashboardQuotesCreateIndexRoute
  DashboardRemindersUuidIndexRoute: typeof DashboardRemindersUuidIndexRoute
  DashboardRemindersCreateIndexRoute: typeof DashboardRemindersCreateIndexRoute
  DashboardUsersUuidIndexRoute: typeof DashboardUsersUuidIndexRoute
  DashboardUsersCreateIndexRoute: typeof DashboardUsersCreateIndexRoute
  DashboardStudyQuickInsightsCategoriesIndexRoute: typeof DashboardStudyQuickInsightsCategoriesIndexRoute
  DashboardStudyQuickInsightsCreateIndexRoute: typeof DashboardStudyQuickInsightsCreateIndexRoute
  DashboardStudyQuickInsightsGroupsIndexRoute: typeof DashboardStudyQuickInsightsGroupsIndexRoute
  DashboardStudyQuickInsightsTagsIndexRoute: typeof DashboardStudyQuickInsightsTagsIndexRoute
  DashboardStudyQuickInsightsUuidPreviewIndexRoute: typeof DashboardStudyQuickInsightsUuidPreviewIndexRoute
  DashboardStudyQuickInsightsCategoriesCreateIndexRoute: typeof DashboardStudyQuickInsightsCategoriesCreateIndexRoute
  DashboardStudyQuickInsightsGroupsCreateIndexRoute: typeof DashboardStudyQuickInsightsGroupsCreateIndexRoute
  DashboardStudyQuickInsightsTagsCreateIndexRoute: typeof DashboardStudyQuickInsightsTagsCreateIndexRoute
}

const DashboardRouteRouteChildren: DashboardRouteRouteChildren = {
  DashboardMediaIndexRoute: DashboardMediaIndexRoute,
  DashboardQuotesIndexRoute: DashboardQuotesIndexRoute,
  DashboardRemindersIndexRoute: DashboardRemindersIndexRoute,
  DashboardStudyIndexRoute: DashboardStudyIndexRoute,
  DashboardUsersIndexRoute: DashboardUsersIndexRoute,
  DashboardWelcomeIndexRoute: DashboardWelcomeIndexRoute,
  DashboardQuotesUuidIndexRoute: DashboardQuotesUuidIndexRoute,
  DashboardQuotesCreateIndexRoute: DashboardQuotesCreateIndexRoute,
  DashboardRemindersUuidIndexRoute: DashboardRemindersUuidIndexRoute,
  DashboardRemindersCreateIndexRoute: DashboardRemindersCreateIndexRoute,
  DashboardUsersUuidIndexRoute: DashboardUsersUuidIndexRoute,
  DashboardUsersCreateIndexRoute: DashboardUsersCreateIndexRoute,
  DashboardStudyQuickInsightsCategoriesIndexRoute:
    DashboardStudyQuickInsightsCategoriesIndexRoute,
  DashboardStudyQuickInsightsCreateIndexRoute:
    DashboardStudyQuickInsightsCreateIndexRoute,
  DashboardStudyQuickInsightsGroupsIndexRoute:
    DashboardStudyQuickInsightsGroupsIndexRoute,
  DashboardStudyQuickInsightsTagsIndexRoute:
    DashboardStudyQuickInsightsTagsIndexRoute,
  DashboardStudyQuickInsightsUuidPreviewIndexRoute:
    DashboardStudyQuickInsightsUuidPreviewIndexRoute,
  DashboardStudyQuickInsightsCategoriesCreateIndexRoute:
    DashboardStudyQuickInsightsCategoriesCreateIndexRoute,
  DashboardStudyQuickInsightsGroupsCreateIndexRoute:
    DashboardStudyQuickInsightsGroupsCreateIndexRoute,
  DashboardStudyQuickInsightsTagsCreateIndexRoute:
    DashboardStudyQuickInsightsTagsCreateIndexRoute,
}

const DashboardRouteRouteWithChildren = DashboardRouteRoute._addFileChildren(
  DashboardRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteRouteWithChildren
  '/login': typeof LoginRoute
  '/dashboard/media': typeof DashboardMediaIndexRoute
  '/dashboard/quotes': typeof DashboardQuotesIndexRoute
  '/dashboard/reminders': typeof DashboardRemindersIndexRoute
  '/dashboard/study': typeof DashboardStudyIndexRoute
  '/dashboard/users': typeof DashboardUsersIndexRoute
  '/dashboard/welcome': typeof DashboardWelcomeIndexRoute
  '/dashboard/quotes/$uuid': typeof DashboardQuotesUuidIndexRoute
  '/dashboard/quotes/create': typeof DashboardQuotesCreateIndexRoute
  '/dashboard/reminders/$uuid': typeof DashboardRemindersUuidIndexRoute
  '/dashboard/reminders/create': typeof DashboardRemindersCreateIndexRoute
  '/dashboard/users/$uuid': typeof DashboardUsersUuidIndexRoute
  '/dashboard/users/create': typeof DashboardUsersCreateIndexRoute
  '/dashboard/study/quick-insights/categories': typeof DashboardStudyQuickInsightsCategoriesIndexRoute
  '/dashboard/study/quick-insights/create': typeof DashboardStudyQuickInsightsCreateIndexRoute
  '/dashboard/study/quick-insights/groups': typeof DashboardStudyQuickInsightsGroupsIndexRoute
  '/dashboard/study/quick-insights/tags': typeof DashboardStudyQuickInsightsTagsIndexRoute
  '/dashboard/study/quick-insights/$uuid/preview': typeof DashboardStudyQuickInsightsUuidPreviewIndexRoute
  '/dashboard/study/quick-insights/categories/create': typeof DashboardStudyQuickInsightsCategoriesCreateIndexRoute
  '/dashboard/study/quick-insights/groups/create': typeof DashboardStudyQuickInsightsGroupsCreateIndexRoute
  '/dashboard/study/quick-insights/tags/create': typeof DashboardStudyQuickInsightsTagsCreateIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteRouteWithChildren
  '/login': typeof LoginRoute
  '/dashboard/media': typeof DashboardMediaIndexRoute
  '/dashboard/quotes': typeof DashboardQuotesIndexRoute
  '/dashboard/reminders': typeof DashboardRemindersIndexRoute
  '/dashboard/study': typeof DashboardStudyIndexRoute
  '/dashboard/users': typeof DashboardUsersIndexRoute
  '/dashboard/welcome': typeof DashboardWelcomeIndexRoute
  '/dashboard/quotes/$uuid': typeof DashboardQuotesUuidIndexRoute
  '/dashboard/quotes/create': typeof DashboardQuotesCreateIndexRoute
  '/dashboard/reminders/$uuid': typeof DashboardRemindersUuidIndexRoute
  '/dashboard/reminders/create': typeof DashboardRemindersCreateIndexRoute
  '/dashboard/users/$uuid': typeof DashboardUsersUuidIndexRoute
  '/dashboard/users/create': typeof DashboardUsersCreateIndexRoute
  '/dashboard/study/quick-insights/categories': typeof DashboardStudyQuickInsightsCategoriesIndexRoute
  '/dashboard/study/quick-insights/create': typeof DashboardStudyQuickInsightsCreateIndexRoute
  '/dashboard/study/quick-insights/groups': typeof DashboardStudyQuickInsightsGroupsIndexRoute
  '/dashboard/study/quick-insights/tags': typeof DashboardStudyQuickInsightsTagsIndexRoute
  '/dashboard/study/quick-insights/$uuid/preview': typeof DashboardStudyQuickInsightsUuidPreviewIndexRoute
  '/dashboard/study/quick-insights/categories/create': typeof DashboardStudyQuickInsightsCategoriesCreateIndexRoute
  '/dashboard/study/quick-insights/groups/create': typeof DashboardStudyQuickInsightsGroupsCreateIndexRoute
  '/dashboard/study/quick-insights/tags/create': typeof DashboardStudyQuickInsightsTagsCreateIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteRouteWithChildren
  '/login': typeof LoginRoute
  '/dashboard/media/': typeof DashboardMediaIndexRoute
  '/dashboard/quotes/': typeof DashboardQuotesIndexRoute
  '/dashboard/reminders/': typeof DashboardRemindersIndexRoute
  '/dashboard/study/': typeof DashboardStudyIndexRoute
  '/dashboard/users/': typeof DashboardUsersIndexRoute
  '/dashboard/welcome/': typeof DashboardWelcomeIndexRoute
  '/dashboard/quotes/$uuid/': typeof DashboardQuotesUuidIndexRoute
  '/dashboard/quotes/create/': typeof DashboardQuotesCreateIndexRoute
  '/dashboard/reminders/$uuid/': typeof DashboardRemindersUuidIndexRoute
  '/dashboard/reminders/create/': typeof DashboardRemindersCreateIndexRoute
  '/dashboard/users/$uuid/': typeof DashboardUsersUuidIndexRoute
  '/dashboard/users/create/': typeof DashboardUsersCreateIndexRoute
  '/dashboard/study/quick-insights/categories/': typeof DashboardStudyQuickInsightsCategoriesIndexRoute
  '/dashboard/study/quick-insights/create/': typeof DashboardStudyQuickInsightsCreateIndexRoute
  '/dashboard/study/quick-insights/groups/': typeof DashboardStudyQuickInsightsGroupsIndexRoute
  '/dashboard/study/quick-insights/tags/': typeof DashboardStudyQuickInsightsTagsIndexRoute
  '/dashboard/study/quick-insights/$uuid/preview/': typeof DashboardStudyQuickInsightsUuidPreviewIndexRoute
  '/dashboard/study/quick-insights/categories/create/': typeof DashboardStudyQuickInsightsCategoriesCreateIndexRoute
  '/dashboard/study/quick-insights/groups/create/': typeof DashboardStudyQuickInsightsGroupsCreateIndexRoute
  '/dashboard/study/quick-insights/tags/create/': typeof DashboardStudyQuickInsightsTagsCreateIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/dashboard'
    | '/login'
    | '/dashboard/media'
    | '/dashboard/quotes'
    | '/dashboard/reminders'
    | '/dashboard/study'
    | '/dashboard/users'
    | '/dashboard/welcome'
    | '/dashboard/quotes/$uuid'
    | '/dashboard/quotes/create'
    | '/dashboard/reminders/$uuid'
    | '/dashboard/reminders/create'
    | '/dashboard/users/$uuid'
    | '/dashboard/users/create'
    | '/dashboard/study/quick-insights/categories'
    | '/dashboard/study/quick-insights/create'
    | '/dashboard/study/quick-insights/groups'
    | '/dashboard/study/quick-insights/tags'
    | '/dashboard/study/quick-insights/$uuid/preview'
    | '/dashboard/study/quick-insights/categories/create'
    | '/dashboard/study/quick-insights/groups/create'
    | '/dashboard/study/quick-insights/tags/create'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/dashboard'
    | '/login'
    | '/dashboard/media'
    | '/dashboard/quotes'
    | '/dashboard/reminders'
    | '/dashboard/study'
    | '/dashboard/users'
    | '/dashboard/welcome'
    | '/dashboard/quotes/$uuid'
    | '/dashboard/quotes/create'
    | '/dashboard/reminders/$uuid'
    | '/dashboard/reminders/create'
    | '/dashboard/users/$uuid'
    | '/dashboard/users/create'
    | '/dashboard/study/quick-insights/categories'
    | '/dashboard/study/quick-insights/create'
    | '/dashboard/study/quick-insights/groups'
    | '/dashboard/study/quick-insights/tags'
    | '/dashboard/study/quick-insights/$uuid/preview'
    | '/dashboard/study/quick-insights/categories/create'
    | '/dashboard/study/quick-insights/groups/create'
    | '/dashboard/study/quick-insights/tags/create'
  id:
    | '__root__'
    | '/'
    | '/dashboard'
    | '/login'
    | '/dashboard/media/'
    | '/dashboard/quotes/'
    | '/dashboard/reminders/'
    | '/dashboard/study/'
    | '/dashboard/users/'
    | '/dashboard/welcome/'
    | '/dashboard/quotes/$uuid/'
    | '/dashboard/quotes/create/'
    | '/dashboard/reminders/$uuid/'
    | '/dashboard/reminders/create/'
    | '/dashboard/users/$uuid/'
    | '/dashboard/users/create/'
    | '/dashboard/study/quick-insights/categories/'
    | '/dashboard/study/quick-insights/create/'
    | '/dashboard/study/quick-insights/groups/'
    | '/dashboard/study/quick-insights/tags/'
    | '/dashboard/study/quick-insights/$uuid/preview/'
    | '/dashboard/study/quick-insights/categories/create/'
    | '/dashboard/study/quick-insights/groups/create/'
    | '/dashboard/study/quick-insights/tags/create/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRouteRoute: typeof DashboardRouteRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRouteRoute: DashboardRouteRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/dashboard",
        "/login"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard/route.tsx",
      "children": [
        "/dashboard/media/",
        "/dashboard/quotes/",
        "/dashboard/reminders/",
        "/dashboard/study/",
        "/dashboard/users/",
        "/dashboard/welcome/",
        "/dashboard/quotes/$uuid/",
        "/dashboard/quotes/create/",
        "/dashboard/reminders/$uuid/",
        "/dashboard/reminders/create/",
        "/dashboard/users/$uuid/",
        "/dashboard/users/create/",
        "/dashboard/study/quick-insights/categories/",
        "/dashboard/study/quick-insights/create/",
        "/dashboard/study/quick-insights/groups/",
        "/dashboard/study/quick-insights/tags/",
        "/dashboard/study/quick-insights/$uuid/preview/",
        "/dashboard/study/quick-insights/categories/create/",
        "/dashboard/study/quick-insights/groups/create/",
        "/dashboard/study/quick-insights/tags/create/"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/dashboard/media/": {
      "filePath": "dashboard/media/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/quotes/": {
      "filePath": "dashboard/quotes/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/reminders/": {
      "filePath": "dashboard/reminders/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/study/": {
      "filePath": "dashboard/study/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/users/": {
      "filePath": "dashboard/users/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/welcome/": {
      "filePath": "dashboard/welcome/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/quotes/$uuid/": {
      "filePath": "dashboard/quotes/$uuid/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/quotes/create/": {
      "filePath": "dashboard/quotes/create/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/reminders/$uuid/": {
      "filePath": "dashboard/reminders/$uuid/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/reminders/create/": {
      "filePath": "dashboard/reminders/create/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/users/$uuid/": {
      "filePath": "dashboard/users/$uuid/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/users/create/": {
      "filePath": "dashboard/users/create/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/study/quick-insights/categories/": {
      "filePath": "dashboard/study/quick-insights/categories/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/study/quick-insights/create/": {
      "filePath": "dashboard/study/quick-insights/create/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/study/quick-insights/groups/": {
      "filePath": "dashboard/study/quick-insights/groups/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/study/quick-insights/tags/": {
      "filePath": "dashboard/study/quick-insights/tags/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/study/quick-insights/$uuid/preview/": {
      "filePath": "dashboard/study/quick-insights/$uuid/preview/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/study/quick-insights/categories/create/": {
      "filePath": "dashboard/study/quick-insights/categories/create/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/study/quick-insights/groups/create/": {
      "filePath": "dashboard/study/quick-insights/groups/create/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/study/quick-insights/tags/create/": {
      "filePath": "dashboard/study/quick-insights/tags/create/index.tsx",
      "parent": "/dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
