import { Type } from 'class-transformer';
import { Author } from './author';
import { BaseModel } from './base-model';
import { QuoteCategory } from './quote-category';

export class Quote extends BaseModel {
	public text: string;

	@Type(() => Author)
	public author: Author | null;

	@Type(() => QuoteCategory)
	public category: QuoteCategory;
}
