import { FloatingActionButton } from "@components/buttons/floating-action-button/floating-action-button.component";
import { ThrashButton } from "@components/buttons/thrash-button/thrash-button.component";
import { SearchInput } from "@components/inputs/search-input/search-input.component";
import { PageTitle } from "@components/page-title/page-title.component";
import { EmptyState } from "@components/states/empty-state/empty-state.component";
import { StatusListFilter } from "@components/status-list-filter/status-list-filter.component";
import { TablePlaceholder } from "@components/table/table-placeholder/table-placeholder.component";
import { Spinner } from "@nextui-org/spinner";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/table";
import { createFileRoute } from "@tanstack/react-router";
import { useQuickInsightGroups } from "@utils/querys/quick-insights/use-quick-insight-groups";

const items = ["All (25)", "Active (10)", "User (22)", "Admin (3)", "Inactive (15)"];

const QuickInsightGroupsList = () => {
	const navigate = Route.useNavigate();

	const { data: groups, isLoading, isSuccess } = useQuickInsightGroups();

	return (
		<div>
			<FloatingActionButton to="create" />

			<div className="flex items-center justify-between">
				<PageTitle title="Quick Insight Groups" />
				<SearchInput onSearch={(search) => navigate({ search })} placeholder="Search groups" />
			</div>
			<div className="mb-9 mt-2 flex items-center justify-between pl-1">
				<StatusListFilter items={items} />
				<ThrashButton />
			</div>

			{isLoading && <TablePlaceholder />}

			{isSuccess && (
				<Table aria-label="Users table" removeWrapper selectionMode="multiple">
					<TableHeader>
						<TableColumn key="name">Name</TableColumn>
						<TableColumn key="createdAt">Created at</TableColumn>
					</TableHeader>
					<TableBody
						className="flex flex-col gap-3"
						emptyContent={<EmptyState className="py-3" content="No results found." />}
						loadingContent={<Spinner size="lg" />}>
						{groups.map((group) => (
							<TableRow key={group.uuid}>
								<TableCell>{group.name}</TableCell>
								<TableCell>{group.createdAt.format("MMMM D, YYYY h:mm A")}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}
		</div>
	);
};

export const Route = createFileRoute("/dashboard/study/quick-insights/groups/")({
	component: QuickInsightGroupsList,
});
