import { Pagination } from '@models/pagination';
import { Quote } from '@models/quote';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToClassFromExist } from 'class-transformer';

export const useQuotes = (page: number) => {
	return useQuery({
		queryKey: ['quotes', page],
		placeholderData: keepPreviousData,
		queryFn: async () => {
			const params = { page };

			return axios.get<Array<Quote>>(`/quotes`, { params }).then(({ data }) => {
				return plainToClassFromExist(new Pagination<Quote>(Quote), data);
			});
		},
	});
};
