export const SearchingFor = () => {
	// const [, setSearchParams] = useSearch({ from: "/login" });

	// return (
	// 	<div className="flex items-center gap-3 font-bold text-white">
	// 		Results for: <span className="font-normal">{search}</span>
	// 		<button className="cursor-pointer" onClick={() => setSearchParams()} type="button">
	// 			<FaTimes color="red" />
	// 		</button>
	// 	</div>
	// );
	return <div />;
};
