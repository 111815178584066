import { QuickInsightGroup } from "@models/quick-insight-group";
import { useQuickInsightGroups } from "@utils/querys/quick-insights/use-quick-insight-groups";
import Select, { Props } from "react-select";

export const QuickInsightGroupsSelect = (props: Props<QuickInsightGroup, true>) => {
	const { data: groups, isSuccess } = useQuickInsightGroups();

	return (
		isSuccess && (
			<Select getOptionLabel={(option) => option.name} isMulti isSearchable options={groups} placeholder="Select groups" {...props} />
		)
	);
};
