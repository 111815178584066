import { QuickInsightGroup } from '@models/quick-insight-group';
import { axios } from '@utils/clients/axios-client';
import { useMutation } from '@utils/helpers/use-mutation';
import { plainToInstance } from 'class-transformer';

export interface CreateQuickInsightGroupDTO {
	name: string;
}

export const useCreateQuickInsightGroup = () => {
	return useMutation({
		mutationFn: async (payload: CreateQuickInsightGroupDTO) => {
			return axios.post('/quick-insights/groups', payload).then(({ data }) => plainToInstance(QuickInsightGroup, data));
		},
	});
};
