import { QuickInsight } from '@models/quick-insight';
import { useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToInstance } from 'class-transformer';

export const useQuickInsight = (uuid: string) => {
	return useQuery({
		queryKey: ['quick-insights', uuid],
		queryFn: async () => {
			return axios.get<QuickInsight>(`/quick-insights/${uuid}`).then(({ data }) => plainToInstance(QuickInsight, data));
		},
	});
};
