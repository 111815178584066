import { User } from "@models/user";
import { cn } from "@utils/helpers/class-name";
import { Blurhash } from "react-blurhash";
import { ImgProps } from "react-html-props";

interface UserAvatarProps extends ImgProps {
	user: User;

	rounded?: boolean;
}

export const UserAvatar = ({ user, rounded = true, ...props }: UserAvatarProps) => {
	const { height, width } = props;

	return (
		<div className={cn("relative overflow-hidden", rounded && "rounded-full")} style={{ height, width }}>
			<Blurhash hash={user.avatarBlurhash} height={height} width={width} />

			<img className={cn("absolute left-0 top-0", rounded && "rounded-full")} src={user.avatar} {...props} />
		</div>
	);
};
