import { createFileRoute } from "@tanstack/react-router";
import { useQuickInsight } from "@utils/querys/quick-insights/use-quick-insight";
import { Blurhash } from "react-blurhash";

export const PreviewQuickInsight = () => {
	const { uuid } = Route.useParams();

	const { data: insight, isLoading, isSuccess } = useQuickInsight(uuid);

	if (isLoading) {
		return <p className="text-white">loading</p>;
	}

	return (
		isSuccess && (
			<div className="text-white">
				<h1 className="text-5xl">{insight.title}</h1>
				<p>{insight.preview}</p>

				{insight.bannerBlurhash && (
					<div className="relative overflow-hidden" style={{ height: 400, width: 400 }}>
						<Blurhash hash={insight.bannerBlurhash} height="100%" width="100%" />

						<img className="absolute left-0 top-0 h-full w-full" src={insight.banner} />
					</div>
				)}

				<div>
					<div dangerouslySetInnerHTML={{ __html: insight.content }} />
				</div>
			</div>
		)
	);
};

export const Route = createFileRoute("/dashboard/study/quick-insights/$uuid/preview/")({
	component: PreviewQuickInsight,
});
