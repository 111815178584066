import { User } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToInstance } from 'class-transformer';

export interface FilterUsersDTO {
	search: string | null;
}

export const useUsers = (params: FilterUsersDTO) => {
	const { search } = params;

	return useQuery({
		queryKey: ['users', search],
		queryFn: async () => {
			const params = { search };

			return axios.get<Array<User>>(`/users`, { params }).then(({ data }) => {
				return plainToInstance(User, data);
			});
		},
	});
};
