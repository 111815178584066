import { Transform, Type } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';
import { BaseModel } from './base-model';
import { QuickInsightCategory } from './quick-insight-category';
import { QuickInsightGroup } from './quick-insight-group';
import { QuickInsightTag } from './quick-insight-tag';
import { User } from './user';

export class QuickInsight extends BaseModel {
	@Type(() => QuickInsightCategory)
	public category: QuickInsightCategory;

	@Type(() => QuickInsightTag)
	public tags: Array<QuickInsightTag>;

	@Type(() => QuickInsightGroup)
	public groups: Array<QuickInsightGroup>;

	public title: string;
	public bannerBlurhash: string | null;

	@Type(() => User)
	public author: User;

	public preview: string;
	public content: string;

	@Transform(({ value }) => (value ? dayjs(value) : null))
	public publishedAt: Dayjs | null;

	public totalComments: number;

	public get banner(): string {
		return `${import.meta.env.VITE_IMAGES_CDN_ENDPOINT}/study/quick-insights/banners/${this.uuid}.webp`;
	}
}
