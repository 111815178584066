import { Type } from 'class-transformer';
import { BaseModel } from './base-model';
import { Media } from './media';

export class Playlist extends BaseModel {
	public name: string;
	public thumbnailBlurhash: string;

	@Type(() => Media)
	public media: Array<Media>;

	public get thumbnail(): string {
		return `${import.meta.env.VITE_IMAGES_CDN_ENDPOINT}/media/thumbnails/${this.uuid}.webp`;
	}
}
