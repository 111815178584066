import { FloatingActionButton } from "@components/buttons/floating-action-button/floating-action-button.component";
import { ThrashButton } from "@components/buttons/thrash-button/thrash-button.component";
import { SearchInput } from "@components/inputs/search-input/search-input.component";
import { PageTitle } from "@components/page-title/page-title.component";
import { SearchingFor } from "@components/searching-for/searching-for.component";
import { EmptyState } from "@components/states/empty-state/empty-state.component";
import { StatusListFilter } from "@components/status-list-filter/status-list-filter.component";
import { TablePlaceholder } from "@components/table/table-placeholder/table-placeholder.component";
import { Checkbox } from "@nextui-org/checkbox";
import { Pagination } from "@nextui-org/pagination";
import { User } from "@sentry/react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useDeleteReminder } from "@utils/mutations/reminders/use-delete-reminder";
import { useReminders } from "@utils/querys/reminders/use-reminders";
import { useSet, useToggle } from "react-use";
import * as yup from "yup";

const items = ["All (25)", "Active (10)", "Inactive (15)"];

const RemindersList = () => {
	const navigate = Route.useNavigate();
	const { page, search } = Route.useSearch();

	const [checked, toggleMasterCheckbox] = useToggle(false);
	const [checkedx, { toggle: toggleUserCheckbox, has, add, remove, clear }] = useSet<string>();

	const { mutateAsync: removeReminder } = useDeleteReminder();
	const { data: pagination, isLoading, isSuccess, refetch } = useReminders({ page, search });

	const toggleAll = () => {
		toggleMasterCheckbox();

		if (pagination) {
			pagination.items.forEach((user) => (checked ? remove(user.uuid) : add(user.uuid)));
		}
	};

	const toggleUser = (user: User) => {
		toggleMasterCheckbox(false);
		toggleUserCheckbox(user.uuid);
	};

	const removeItems = async () => {
		if (confirm("remove selected items?")) {
			return Promise.all(Array.from(checkedx).map((uuid) => removeReminder(uuid))).then(() => {
				clear();
				refetch();
			});
		}
	};

	const changePage = (page: number) => {
		clear();
		toggleMasterCheckbox(false);
		navigate({ search: { page, search } });
	};

	return (
		<div>
			<FloatingActionButton to="create" />

			<div className="flex items-center justify-between">
				<PageTitle title="Reminders" />
				<SearchInput onSearch={(search) => navigate({ search: { search } })} placeholder="Search reminders" />
			</div>
			<div className="mb-9 mt-2 flex items-center justify-between pl-1">
				<StatusListFilter items={items} />
				<ThrashButton onClick={removeItems} />
			</div>

			{isLoading && <TablePlaceholder />}

			{isSuccess && (
				<>
					{pagination.controls.hasPagination && (
						<div className="flex items-center gap-3">
							<Pagination
								className="dark"
								color="primary"
								initialPage={pagination.controls.currentPage}
								onChange={changePage}
								showControls
								total={pagination.controls.totalPages}
							/>
							<div className="text-white">{pagination.controls.totalItems} items</div>
						</div>
					)}

					<div className="w-full text-left text-white">
						{search && <SearchingFor />}

						{pagination.empty ? (
							<EmptyState className="py-3" content="No results found." />
						) : (
							<>
								<div className="flex h-10 items-center px-3 text-xs font-medium">
									<div className="flex w-10 items-center justify-center" key="checkbox">
										<Checkbox checked={checked} onChange={toggleAll} />
									</div>
									<div className="w-9/12">Text</div>
									<div className="w-2/12">Date</div>
									<div className="w-1/12">Hour</div>
								</div>
								<div className="flex flex-col gap-3">
									{pagination.items.map((reminder) => (
										<div className="flex items-center rounded bg-black p-3 text-sm font-medium" key={reminder.uuid}>
											<div className="flex w-10 items-center justify-center" key="checkbox">
												<Checkbox checked={has(reminder.uuid)} onChange={() => toggleUser(reminder)} />
											</div>
											<Link className="w-9/12 whitespace-pre" key="name" to={reminder.uuid}>
												{reminder.text}
											</Link>
											<div className="w-2/12" key="date">
												{reminder.createdAt.format("MMMM DD, YYYY")}
											</div>
											<div className="w-1/12" key="hour">
												{reminder.createdAt.format("HH:mm")}
											</div>
										</div>
									))}
								</div>
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export const Route = createFileRoute("/dashboard/reminders/")({
	component: RemindersList,

	validateSearch: (search) => {
		const schema = yup.object({
			search: yup.string(),
			page: yup.number().default(1),
		});

		return schema.validateSync(search);
	},
});
