/* eslint-disable */
import { Exclude, Type } from 'class-transformer';
import { PaginationControls } from './pagination-controls';

export class Pagination<T> {
	@Exclude()
	private type: Function;

	@Type((options) => (options?.newObject as Pagination<T>).type)
	public items: Array<T>;

	@Type(() => PaginationControls)
	public meta: PaginationControls;

	constructor(type: Function) {
		this.type = type;
	}

	public get controls(): PaginationControls {
		return this.meta;
	}

	public get empty(): boolean {
		return this.items.length === 0;
	}
}
