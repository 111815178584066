import { QuickInsight } from '@models/quick-insight';
import { QuickInsightCategory } from '@models/quick-insight-category';
import { QuickInsightGroup } from '@models/quick-insight-group';
import { QuickInsightTag } from '@models/quick-insight-tag';
import { axios } from '@utils/clients/axios-client';
import { useMutation } from '@utils/helpers/use-mutation';
import { plainToInstance } from 'class-transformer';

export interface CreateQuickInsightDTO {
	tags: Array<QuickInsightTag>;
	category: QuickInsightCategory;
	groups: Array<QuickInsightGroup>;

	title: string;
	preview: string;
	content: string;
	banner: string | null;

	publishedAt: Date | null;
}

export const useCreateQuickInsight = () => {
	return useMutation({
		mutationFn: async (payload: CreateQuickInsightDTO) => {
			return axios.post('/quick-insights', payload).then(({ data }) => plainToInstance(QuickInsight, data));
		},
	});
};
