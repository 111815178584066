export class PaginationControls {
	public totalItems: number;
	public itemCount: number;
	public itemsPerPage: number;
	public totalPages: number;
	public currentPage: number;

	public get hasPagination(): boolean {
		return this.totalPages > 1;
	}
}
