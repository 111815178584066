import { Reminder } from '@models/reminder';
import { useQueryClient } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { useMutation } from '@utils/helpers/use-mutation';
import { plainToInstance } from 'class-transformer';

export interface UpdateReminderDTO {
	text: string;
}

export const useUpdateReminder = (reminder: Reminder) => {
	const client = useQueryClient();

	return useMutation({
		onSuccess: () => {
			client.removeQueries({ queryKey: ['reminders'] });
		},
		mutationFn: async (payload: UpdateReminderDTO) => {
			return axios.patch<Reminder>(`/reminders/${reminder.uuid}`, payload).then(({ data }) => plainToInstance(Reminder, data));
		},
	});
};
