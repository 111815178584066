import { ButtonProps } from "react-html-props";
import { FiTrash2 } from "react-icons/fi";

export const ThrashButton = (props: ButtonProps) => {
	return (
		<button className="flex h-10 w-10 items-center justify-center rounded-full bg-white" type="button" {...props}>
			<FiTrash2 color="black" />
		</button>
	);
};
