import BetOnUsWhiteIcon from "@assets/images/icons/bet-on-us-white.svg";
import { Button } from "@components/buttons/button.component";
import { UserAvatar } from "@components/user-avatar/user-avatar.component";
import { useSession } from "@providers/session/session.provider";
import { Link, MatchRoute } from "@tanstack/react-router";
import { cn } from "@utils/helpers/class-name";
import { FiBookOpen } from "react-icons/fi";
import { LiaUserSolid } from "react-icons/lia";
import { MdArrowDownward, MdArrowForward, MdBookmarkBorder, MdFormatQuote } from "react-icons/md";
import { PiWaveform } from "react-icons/pi";

const sections: Array<{
	url: string;
	title: string;
	icon: React.JSX.Element;
}> = [
	{ icon: <MdArrowDownward size={22} />, title: "Dashboard", url: "/dashboard/welcome" },
	{ icon: <PiWaveform size={22} />, title: "Media", url: "/dashboard/media" },
	{ icon: <FiBookOpen size={22} />, title: "Study", url: "/dashboard/study" },
	{ icon: <MdFormatQuote size={22} />, title: "Quotes", url: "/dashboard/quotes" },
	{ icon: <MdBookmarkBorder size={22} />, title: "Reminders", url: "/dashboard/reminders" },
	{ icon: <LiaUserSolid size={22} />, title: "Users", url: "/dashboard/users" },
];

export const Sidebar = () => {
	const { user, clearSession } = useSession();

	return (
		<div className="flex h-fit w-48 min-w-48 flex-col items-center justify-between bg-blue-primary">
			<img className="mt-14 h-16 w-16" src={BetOnUsWhiteIcon} />

			<div className="flex flex-col items-center justify-center pt-4">
				<div className="mt-6 flex flex-col items-center justify-center gap-4">
					{sections.map(({ url, icon, title }, index) => {
						return (
							<MatchRoute key={index} to={url}>
								{(match) => (
									<Link className="flex flex-col items-center" to={url}>
										<div
											className={cn(
												"mb-1 flex h-14 w-14 items-center justify-center rounded-full",
												match ? "bg-white text-black" : "bg-black text-white",
											)}>
											{icon}
										</div>
										<div className="text-center text-xs font-semibold text-white">{title}</div>
									</Link>
								)}
							</MatchRoute>
						);
					})}
				</div>
			</div>
			<div className="flex flex-col items-center justify-center px-3 pb-6 pt-8">
				<UserAvatar height={85} rounded user={user} width={85} />

				<div className="mt-3">
					<h4 className="text-center text-xs font-semibold text-white">
						Welcome
						<br />
						<span className="text-blue-active">{user.name}</span>
					</h4>
				</div>

				<div className="w-full" onClick={() => clearSession()}>
					<Button icon={<MdArrowForward size={18} />} value="LOGOUT" />
				</div>
			</div>
		</div>
	);
};
