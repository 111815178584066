import { QuickInsightTag } from '@models/quick-insight-tag';
import { useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToInstance } from 'class-transformer';

export const useQuickInsightTags = () => {
	return useQuery({
		queryKey: ['quick-insight-tags'],
		queryFn: async () => {
			return axios.get<Array<QuickInsightTag>>(`/quick-insights/tags`).then(({ data }) => plainToInstance(QuickInsightTag, data));
		},
	});
};
