import { QuickInsightTag } from "@models/quick-insight-tag";
import { useQuickInsightTags } from "@utils/querys/quick-insights/use-quick-insight-tags";
import Select, { Props } from "react-select";

export const QuickInsightTagsSelect = (props: Props<QuickInsightTag, true>) => {
	const { data: tags, isSuccess } = useQuickInsightTags();

	return (
		isSuccess && (
			<Select
				getOptionLabel={(option) => option.name}
				isClearable
				isMulti
				isSearchable
				options={tags}
				placeholder="Select tags"
				{...props}
			/>
		)
	);
};
