import * as Sentry from '@sentry/react';
import { createRouter } from '@utils/helpers/create-router';

const router = createRouter();

Sentry.init({
	dsn: 'https://8a5edfb2546c3fbd662e447b7e405e48@o4507906446458880.ingest.us.sentry.io/4508129288323072',

	integrations: [Sentry.replayIntegration(), Sentry.tanstackRouterBrowserTracingIntegration(router)],

	tracesSampleRate: 1.0,
	tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
