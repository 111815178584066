import { ErrorState } from "@components/states/error-state/error-state.component";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import {
	CreateQuickInsightCategoryDTO,
	useCreateQuickInsightCategory,
} from "@utils/mutations/quick-insights/use-create-quick-insight-category";
import { useForm } from "@utils/validations/use-form";
import * as yup from "yup";

export const CreateQuickInsightCategories = () => {
	const { mutate: create, isSuccess, error } = useCreateQuickInsightCategory();

	const form = useForm<CreateQuickInsightCategoryDTO>({
		onSubmit: async ({ value }) => create(value),
		validators: {
			onChange: yup.object({
				name: yup.string().max(30).required(),
			}),
		},
	});

	if (isSuccess) {
		return <Navigate to="/dashboard/study/quick-insights/categories" />;
	}

	return (
		<div>
			<h1 className="text-white">Create Quick Insight Category</h1>

			<form.Field name="name">
				{(field) => (
					<input
						className="mt-2 h-10 w-full rounded bg-white bg-opacity-20 px-4 py-3 text-sm font-normal text-white text-opacity-50"
						onBlur={field.handleBlur}
						onChange={(e) => field.handleChange(e.target.value)}
						placeholder="Name"
					/>
				)}
			</form.Field>

			{error && <ErrorState errors={error} />}

			<div onClick={() => form.handleSubmit()}>SEND</div>
		</div>
	);
};

export const Route = createFileRoute("/dashboard/study/quick-insights/categories/create/")({
	component: CreateQuickInsightCategories,
});
