import BetOnUsHalfBackgroundIcon from "@assets/images/backgrounds/half-big-bet-on-us-icon.svg";
import BetOnUsBlackIcon from "@assets/images/icons/bet-on-us-black.svg";
import BetOnUsBlueIcon from "@assets/images/icons/bet-on-us-blue.svg";
import BetOnUsControlPanelImage from "@assets/images/icons/bet-on-us-control-panel.svg";
import { LoginForm } from "@components/forms/login/login.form";
import { useSession } from "@providers/session/session.provider";
import { createFileRoute, Navigate, useNavigate } from "@tanstack/react-router";
import { LoginDTO, useLogin } from "@utils/mutations/session/use-login";

const Login = () => {
	const navigate = useNavigate();

	const { mutateAsync, errors } = useLogin();
	const { isExpired, startSession } = useSession();

	const login = async (payload: LoginDTO) => {
		return mutateAsync(payload).then((token) => {
			startSession(token);
			navigate({ to: "/login", replace: true });
		});
	};

	if (!isExpired) {
		return <Navigate replace={true} to="/dashboard/welcome" />;
	}

	return (
		<div className="flex h-screen flex-row">
			<div className="grow">
				<div className="relative z-10 m-20">
					<div className="flex items-center gap-4 pb-5">
						<img src={BetOnUsBlueIcon} />
						<div className="font-monument text-2xl font-normal text-white">BET ON US</div>
					</div>

					<img src={BetOnUsControlPanelImage} />
				</div>

				<img className="absolute bottom-0 left-0 w-[350px]" src={BetOnUsHalfBackgroundIcon} />
				<div className="absolute bottom-0 left-0 h-[700px] w-[700px] flex-shrink-0 rounded bg-blue-secondary blur-[500px] filter" />
			</div>
			<div className="flex w-[485px] items-end justify-center bg-blue-primary">
				<div className="flex w-full flex-col items-center">
					<img src={BetOnUsBlackIcon} />
					<div className="w-full px-20 pb-20 pt-10">
						<h3 className="text-center text-xl font-normal text-white">Login and access</h3>

						<LoginForm errors={errors} onSubmit={login} />
					</div>
				</div>
			</div>
		</div>
	);
};

export const Route = createFileRoute("/login")({
	component: Login,
});
