import { axios } from '@utils/clients/axios-client';
import { useMutation } from '@utils/helpers/use-mutation';

export interface TokenDTO {
	token: string;
}

export interface LoginDTO {
	email: string;
	password: string;
}

export const useLogin = () => {
	return useMutation({
		mutationFn: async (payload: LoginDTO): Promise<string> => {
			return axios.post<TokenDTO>('/auth/login', payload).then(({ data: { token } }) => token);
		},
	});
};
