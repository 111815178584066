import { FloatingActionButton } from "@components/buttons/floating-action-button/floating-action-button.component";
import { ThrashButton } from "@components/buttons/thrash-button/thrash-button.component";
import { SearchInput } from "@components/inputs/search-input/search-input.component";
import { PageTitle } from "@components/page-title/page-title.component";
import { StatusListFilter } from "@components/status-list-filter/status-list-filter.component";
import { TablePlaceholder } from "@components/table/table-placeholder/table-placeholder.component";
import { UserAvatar } from "@components/user-avatar/user-avatar.component";
import { User } from "@models/user";
import { createFileRoute, Link, useNavigate, useSearch } from "@tanstack/react-router";
import { cn } from "@utils/helpers/class-name";
import { useUsers } from "@utils/querys/users/use-users";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";

const items = ["All (25)", "Active (10)", "User (22)", "Admin (3)", "Inactive (15)"];

const UsersList = () => {
	const navigate = useNavigate({ from: Route.fullPath });

	const { search } = useSearch({
		from: Route.fullPath,
	});

	const { data: users, isLoading, isSuccess } = useUsers({ search });

	return (
		<div>
			<FloatingActionButton to="create" />

			<div className="flex items-center justify-between">
				<PageTitle title="Users" />
				<SearchInput onSearch={(search) => navigate({ search })} placeholder="Search users" />
			</div>
			<div className="mb-9 mt-2 flex items-center justify-between pl-1">
				<StatusListFilter items={items} />
				<ThrashButton />
			</div>

			{isLoading && <TablePlaceholder />}

			{isSuccess && (
				<div className="text-white">
					<div className="flex items-center gap-6 px-6 py-3 text-xs font-medium">
						<div className="w-10" />
						<div className="flex flex-1 items-center gap-2">
							<span>Name</span>
							<HiOutlineArrowNarrowDown />
						</div>
						<div className="flex flex-1 items-center gap-2">
							<span>Email</span>
							<HiOutlineArrowNarrowDown />
						</div>
						<div className="flex w-72 items-center gap-2">
							<span>Created At</span>
							<HiOutlineArrowNarrowDown />
						</div>
						<div className="flex w-14 items-center gap-2">
							<span>Role</span>
							<HiOutlineArrowNarrowDown />
						</div>
					</div>
					<div className="flex flex-col gap-3">
						{users.map((user: User) => (
							<Link className="flex h-14 items-center gap-6 rounded bg-black px-6 text-sm" key={user.uuid} to={user.uuid}>
								<div className="w-10">
									<UserAvatar height={38} user={user} width={38} />
								</div>
								<div className="flex-1 font-bold">{user.name}</div>
								<div className="flex-1">{user.email}</div>
								<div className="w-72">{user.createdAt.format("MMMM D, YYYY h:mm A")}</div>
								<div className={cn("w-14 font-medium italic", user.role.isAdmin ? "text-blue-active" : "text-gray-200 text-opacity-50")}>
									{user.role.name}
								</div>
							</Link>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export const Route = createFileRoute("/dashboard/users/")({
	component: UsersList,
});
