import { DivProps } from "react-html-props";
import emptyImage from "./empty-state.png";

export interface EmptyStateProps extends DivProps {
	header?: string;
	content?: string;
}

export const EmptyState = ({ header = "Ops!", content = "We don't have any content here yet.", className }: EmptyStateProps) => {
	return (
		<div className={className}>
			<div className="my-2 flex items-center gap-4 rounded-xl bg-black">
				<img className="relative -left-2 -top-2 h-[121px] w-[98px]" src={emptyImage} />
				<div className="text-left text-white">
					<h3 className="text-2xl font-normal">{header}</h3>
					<h4 className="text-base font-normal">{content}</h4>
				</div>
			</div>
		</div>
	);
};
