import { PaginatorPlaceholder } from "@components/paginator/paginator-placeholder.compont";
import ContentLoader from "react-content-loader";
import { TableRowPlaceholder } from "./table-row-placeholder";

interface TablePlaceholderProps {
	length?: number;
	withPagination?: boolean;
}

export const TablePlaceholder = ({ withPagination, length = 10 }: TablePlaceholderProps) => {
	return (
		<div className="mt-9 w-full">
			{withPagination && <PaginatorPlaceholder />}
			<div className="flex h-10 items-center">
				<ContentLoader backgroundColor="#333" foregroundColor="#999" height="20px" viewBox="0 0 100% 40" width="100%">
					<rect height="100%" rx="5" ry="5" width="100%" />
				</ContentLoader>
			</div>
			<div className="flex flex-col gap-3">
				{Array.from({ length }, (_, index) => (
					<TableRowPlaceholder key={index} />
				))}
			</div>
		</div>
	);
};
