import { plainToInstance } from 'class-transformer';
import { decodeToken, isExpired } from 'react-jwt';
import { JWTPayload } from './jwt-payload';
import { User } from './user';

export class Session {
	public clearSession: () => void;
	public startSession: (token: string) => void;

	constructor(public readonly token: string) {}

	public get user(): User {
		const value = decodeToken<JWTPayload>(this.token);

		if (value && !this.isExpired) {
			return plainToInstance(User, value.user);
		}

		throw new Error(`Invalid token: ${this.token}`);
	}

	public get isExpired(): boolean {
		return isExpired(this.token);
	}
}
