import { QueryClientConfig } from '@tanstack/react-query';
import ms from 'ms';

export const queryClientOptions: QueryClientConfig = {
	defaultOptions: {
		queries: {
			staleTime: ms('1d'),
			refetchOnWindowFocus: false,
		},
	},
};
