import { Transform } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';

export class SharedBaseModel {
	public uuid: string;

	public get value(): string {
		return this.uuid;
	}
}

export class BaseModel extends SharedBaseModel {
	@Transform(({ value }) => dayjs(value))
	public createdAt: Dayjs;

	@Transform(({ value }) => dayjs(value))
	public updatedAt: Dayjs;

	@Transform(({ value }) => (value ? dayjs(value) : null))
	public deletedAt: Dayjs | null;

	public get active(): boolean {
		return !this.deletedAt;
	}
}

export class TimestamplessBaseModel extends SharedBaseModel {}
