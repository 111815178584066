import { createFileRoute, useParams } from "@tanstack/react-router";
import { useReminder } from "@utils/querys/reminders/use-reminder";

const Reminder = () => {
	const { uuid } = useParams({ from: Route.fullPath });

	const { data: reminder } = useReminder(uuid!);

	console.log(reminder);

	return <div />;
};

export const Route = createFileRoute("/dashboard/reminders/$uuid/")({
	component: Reminder,
});
