import { Reminder } from '@models/reminder';
import { useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToInstance } from 'class-transformer';

export const useReminder = (uuid?: string) => {
	return useQuery({
		enabled: !!uuid,
		queryKey: ['reminders', uuid],
		queryFn: async () => {
			return axios.get<Reminder>(`/reminders/${uuid}`).then(({ data }) => {
				return plainToInstance(Reminder, data);
			});
		},
	});
};
