import JoditEditor, { IJoditEditorProps, Jodit } from "jodit-react";
import { useMemo } from "react";
import "./wysiwyg-editor.css";

interface WYSIWYGEditorProps {
	value: string;

	onBlur: (newValue: string) => void;
}

const imageAsBase64Picker = {
	icon: "image",
	tooltip: "Insert image",
	exec: function (editor: Jodit) {
		const input = document.createElement("input");

		input.setAttribute("type", "file");
		input.setAttribute("accept", "image/*");

		input.click();

		input.onchange = async function () {
			if (input.files && input.files.length) {
				const imageFile = input.files[0];
				const reader = new FileReader();

				reader.readAsDataURL(imageFile);

				reader.onload = () => {
					const result = reader.result as string;

					if (result) {
						const image = editor.createInside.element("img");

						image.setAttribute("src", result);
						image.setAttribute("alt", imageFile.name);

						editor.selection.insertNode(image);
					}
				};
			}
		};
	},
};

const wysiwygEditorConfig = {
	height: 800,
	statusbar: false,
	toolbarButtonSize: "large",

	style: {
		padding: "2em",
	},

	cleanHTML: {
		fillEmptyParagraph: false,
	},

	disablePlugins: ["paste", "clipboard", "classSpan", "aiAssistant", "preview", "print"],

	buttons: [
		"bold",
		"italic",
		"underline",
		"strikethrough",
		"center",
		"justify",
		"left",
		"right",
		"ul",
		"ol",
		"outdent",
		"indent",
		"subscript",
		"superscript",
		"link",
		imageAsBase64Picker,
		"---",
		"fullsize",
	],
} as IJoditEditorProps["config"];

export const WYSIWYGEditor = ({ value, onBlur }: WYSIWYGEditorProps) => {
	const config = useMemo(() => wysiwygEditorConfig, []);

	return <JoditEditor config={config} onBlur={onBlur} value={value} />;
};
