import { CreateUserForm } from "@components/forms/users/create-user/create-user.form";
import { PageTitle } from "@components/page-title/page-title.component";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import { useCreateUser } from "@utils/mutations/users/use-create-user";

export const CreateUser = () => {
	const { mutateAsync: createUser, errors, isSuccess } = useCreateUser();

	if (isSuccess) {
		return <Navigate to="/dashboard/users" />;
	}

	return (
		<div>
			<PageTitle className="mb-3" title="Create User" />

			<CreateUserForm errors={errors} onSubmit={createUser} />
		</div>
	);
};

export const Route = createFileRoute("/dashboard/users/create/")({
	component: CreateUser,
});
