import { Playlist } from "@models/playlist";
import { Link } from "@tanstack/react-router";

interface PlaylistCardProps {
	playlist: Playlist;
}

export const PlaylistCard = ({ playlist }: PlaylistCardProps) => {
	return (
		<Link to={playlist.uuid}>
			<div className="rounded bg-white p-3">
				<img className="mb-3 aspect-square w-full rounded-t object-cover" src={playlist.thumbnail} />

				<div>
					<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{playlist.name}</h5>
					<div className="font-normal text-gray-700 dark:text-gray-400">
						{playlist.media.map((media) => (
							<p key={media.uuid}>
								{media.title} <small>{media.timestamp}</small>
							</p>
						))}
					</div>
				</div>
			</div>
		</Link>
	);
};
