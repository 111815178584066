import { User } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToInstance } from 'class-transformer';

export const useUser = (uuid: string) => {
	return useQuery({
		queryKey: ['user', uuid],
		queryFn: async () => {
			return axios.get<User>(`/users/${uuid}`).then(({ data }) => plainToInstance(User, data));
		},
	});
};
