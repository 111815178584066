import { queryClientOptions } from "@configs/query-client";
import { NextUIProvider } from "@nextui-org/system";
import { SessionProvider } from "@providers/session/session.provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

interface ProvidersProps {
	children: React.ReactNode;
}

export const Providers = ({ children }: ProvidersProps) => {
	const queryClient = new QueryClient(queryClientOptions);

	return (
		<QueryClientProvider client={queryClient}>
			<NextUIProvider>
				<SessionProvider>{children}</SessionProvider>
				<ReactQueryDevtools buttonPosition="top-right" initialIsOpen={false} />
			</NextUIProvider>
		</QueryClientProvider>
	);
};
