import { Button } from "@components/buttons/button.component";
import { FormProps } from "@interfaces/form-props";
import { LoginDTO } from "@utils/mutations/session/use-login";
import { useForm } from "@utils/validations/use-form";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdArrowForward } from "react-icons/md";
import * as yup from "yup";

export const LoginForm = ({ onSubmit, errors }: FormProps<LoginDTO>) => {
	const form = useForm<LoginDTO>({
		onSubmit: async ({ value }) => onSubmit(value),
		validators: {
			onChange: yup.object({
				email: yup.string().email().required(),
				password: yup.string().min(12).required(),
			}),
		},
	});

	return (
		<div>
			<form.Field name="email">
				{(field) => (
					<input
						aria-label={field.name}
						className="mt-2 h-10 w-full rounded bg-white bg-opacity-20 px-4 py-3 text-sm font-normal text-white text-opacity-50"
						name={field.name}
						onBlur={field.handleBlur}
						onChange={(e) => field.handleChange(e.target.value)}
						placeholder="email@domain.com"
					/>
				)}
			</form.Field>

			<form.Field name="password">
				{(field) => (
					<input
						aria-label={field.name}
						className="mt-2 h-10 w-full rounded bg-white bg-opacity-20 px-4 py-3 text-sm font-normal text-white text-opacity-50"
						onBlur={field.handleBlur}
						onChange={(e) => field.handleChange(e.target.value)}
						placeholder="**********"
						type="password"
					/>
				)}
			</form.Field>

			{!!errors.length && <div className="px-4 pt-2 text-xs text-red-error">Email ou senha incorretos. Tente novamente!</div>}

			<form.Subscribe selector={(state) => [state.isPristine || !state.canSubmit, state.isSubmitting]}>
				{([disabled, isSubmitting]) => (
					<Button
						disabled={disabled}
						icon={isSubmitting ? <AiOutlineLoading3Quarters className="animate-spin" /> : <MdArrowForward size={22} />}
						onClick={() => form.handleSubmit()}
						type="submit"
						value="LOGIN"
					/>
				)}
			</form.Subscribe>
		</div>
	);
};
