import { Type } from 'class-transformer';
import { BaseModel } from './base-model';
import { Playlist } from './playlist';

export class Media extends BaseModel {
	public title: string;

	public author: string | null;

	public thumbnail: string;
	public thumbnailBlurhash: string | null;

	public duration: number;

	public isVideo: boolean;

	@Type(() => Playlist)
	public playlist: Playlist;

	public url: string;

	public get timestamp(): string {
		return new Date(this.duration * 1000).toISOString().substring(14, 19);
	}
}
