import { FloatingActionButton } from "@components/buttons/floating-action-button/floating-action-button.component";
import { ThrashButton } from "@components/buttons/thrash-button/thrash-button.component";
import { SearchInput } from "@components/inputs/search-input/search-input.component";
import { PageTitle } from "@components/page-title/page-title.component";
import { EmptyState } from "@components/states/empty-state/empty-state.component";
import { StatusListFilter } from "@components/status-list-filter/status-list-filter.component";
import { TablePlaceholder } from "@components/table/table-placeholder/table-placeholder.component";
import { Pagination } from "@nextui-org/pagination";
import { Spinner } from "@nextui-org/spinner";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/table";
import { createFileRoute, useNavigate, useSearch } from "@tanstack/react-router";

import { useQuotes } from "@utils/querys/quotes/use-quotes";

const items = ["All (25)", "Active (10)", "Inactive (15)"];

const QuotesList = () => {
	const navigate = useNavigate({ from: Route.fullPath });

	const { page } = useSearch({
		from: Route.fullPath,
	});

	const { data: pagination, isLoading, isSuccess } = useQuotes(page);

	return (
		<div>
			<FloatingActionButton to="create" />

			<div className="flex items-center justify-between">
				<PageTitle title="Quotes" />
				<SearchInput onSearch={() => {}} placeholder="Search quotes" />
			</div>
			<div className="mb-9 mt-2 flex items-center justify-between pl-1">
				<StatusListFilter items={items} />
				<ThrashButton onClick={() => {}} />
			</div>

			{isLoading && <TablePlaceholder length={20} />}

			{isSuccess && (
				<Table
					aria-label="Quotes list"
					removeWrapper
					selectionMode="multiple"
					topContent={
						pagination.controls.hasPagination && (
							<div className="flex w-full justify-center">
								<Pagination
									className="dark"
									color="primary"
									initialPage={pagination.controls.currentPage}
									onChange={(page: number) => navigate({ search: { page } })}
									showControls
									total={pagination.controls.totalPages}
								/>
							</div>
						)
					}>
					<TableHeader>
						<TableColumn key="quote">Quote</TableColumn>
						<TableColumn key="author">Author</TableColumn>
						<TableColumn key="category">Category</TableColumn>
						<TableColumn key="createdAt">Created at</TableColumn>
					</TableHeader>
					<TableBody
						className="flex flex-col gap-3"
						emptyContent={<EmptyState className="py-3" content="No results found." />}
						loadingContent={<Spinner size="lg" />}>
						{pagination.items.map((quote) => (
							<TableRow key={quote.uuid}>
								<TableCell>{quote.text}</TableCell>
								<TableCell width="15%">{quote.author ? quote.author.name : "-"}</TableCell>
								<TableCell width="15%">{quote.category.name}</TableCell>
								<TableCell width="15%">{quote.createdAt.format("MMMM D, YYYY h:mm A")}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}
		</div>
	);
};

export const Route = createFileRoute("/dashboard/quotes/")({
	component: QuotesList,
});
