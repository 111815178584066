import { Sidebar } from "@components/sidebar/sidebar.component";
import { useSession } from "@providers/session/session.provider";
import { createFileRoute, Navigate, Outlet } from "@tanstack/react-router";

const Dashboard = () => {
	const { isExpired } = useSession();

	if (isExpired) {
		return <Navigate replace={true} to="/login" />;
	}

	return (
		<div className="flex flex-row">
			<Sidebar />
			<div className="grow px-14 py-11">
				<Outlet />
			</div>
		</div>
	);
};

export const Route = createFileRoute("/dashboard")({
	component: Dashboard,
});
