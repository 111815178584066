import { QuickInsightCategorySelect } from "@components/selects/quick-insight-category-select/quick-insight-category-select.component";
import { QuickInsightGroupsSelect } from "@components/selects/quick-insight-groups-select/quick-insight-groups-select.component";
import { QuickInsightTagsSelect } from "@components/selects/quick-insight-tags-select/quick-insight-tags-select.component";
import { ErrorState } from "@components/states/error-state/error-state.component";
import { WYSIWYGEditor } from "@components/wysiwyg-editor/wysiwyg-editor.component";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import { CreateQuickInsightDTO, useCreateQuickInsight } from "@utils/mutations/quick-insights/use-create-quick-insight";
import { useForm } from "@utils/validations/use-form";
import React, { useRef } from "react";
import * as yup from "yup";

export const CreateQuickInsight = () => {
	const uploadInputRef = useRef<HTMLInputElement>(null);

	const { mutateAsync: create, isSuccess, error } = useCreateQuickInsight();

	const form = useForm<CreateQuickInsightDTO>({
		onSubmit: async ({ value }) => {
			console.log(value);
			create(value);
		},
		validators: {
			onChange: yup.object({
				tags: yup.array(yup.string()).required(),
				category: yup.array(yup.string()).required(),
				groups: yup.array(yup.string()).required(),

				title: yup.string().max(120).required(),
				preview: yup.string().required(),
				content: yup.string().required(),
				banner: yup.string(),

				publishedAt: yup.date(),
			}),
		},
	});

	const setBanner = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;

		if (!files) return;

		const file = files[0];
		const reader = new FileReader();

		if (!file) return;

		reader.readAsDataURL(file);
		reader.onload = async () => {
			const image = reader.result as string;

			if (image) {
				form.setFieldValue("banner", image);
			}
		};
	};

	if (isSuccess) {
		return <Navigate to="/dashboard/study/quick-insights" />;
	}

	return (
		<div>
			<input accept="image/*" onChange={setBanner} ref={uploadInputRef} type="file" />

			<form.Field name="tags">
				{(field) => (
					<QuickInsightTagsSelect
						onBlur={field.handleBlur}
						onChange={(options) => field.handleChange(options.map((option) => option))}
						value={field.state.value}
					/>
				)}
			</form.Field>

			<form.Field name="category">
				{(field) => (
					<QuickInsightCategorySelect
						onBlur={field.handleBlur}
						onChange={(option) => option && field.handleChange(option)}
						value={field.state.value}
					/>
				)}
			</form.Field>

			<form.Field name="groups">
				{(field) => (
					<QuickInsightGroupsSelect
						onBlur={field.handleBlur}
						onChange={(options) => field.handleChange(options.map((option) => option))}
						value={field.state.value}
					/>
				)}
			</form.Field>

			<form.Field name="title">
				{(field) => (
					<input
						className="mt-2 h-10 w-full rounded bg-white bg-opacity-20 px-4 py-3 text-sm font-normal text-white text-opacity-50"
						onBlur={field.handleBlur}
						onChange={(e) => field.handleChange(e.target.value)}
						placeholder="Title"
					/>
				)}
			</form.Field>

			<form.Field name="preview">
				{(field) => (
					<textarea
						className="mt-2 w-full rounded bg-white bg-opacity-20 px-4 py-3 text-sm font-normal text-white text-opacity-50"
						onBlur={field.handleBlur}
						onChange={(e) => field.handleChange(e.target.value)}
						placeholder="Preview"
						rows={10}
					/>
				)}
			</form.Field>

			<form.Field name="content">
				{(field) => <WYSIWYGEditor onBlur={(value) => field.handleChange(value)} value={field.state.value} />}
			</form.Field>

			{error && <ErrorState errors={error} />}

			<div onClick={() => form.handleSubmit()}>TEST</div>
		</div>
	);
};

export const Route = createFileRoute("/dashboard/study/quick-insights/create/")({
	component: CreateQuickInsight,
});
