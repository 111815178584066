import { clearStorage, getTokenFromStorage } from '@utils/helpers/manage-storage';
import lib, { HttpStatusCode } from 'axios';

const instance = lib.create({
	baseURL: import.meta.env.VITE_API_ENDPOINT,

	headers: {
		'Content-Type': 'application/json',
	},
});

instance.interceptors.request.use((config) => {
	config.headers.Authorization = `Bearer ${getTokenFromStorage()}`;

	return config;
});

instance.interceptors.response.use(
	async ({ data }) => data,
	async ({ response }) => {
		const token = getTokenFromStorage();

		if (response?.status === HttpStatusCode.Unauthorized && token) {
			return clearStorage();
		}

		return Promise.reject(response?.data.errors || [response?.data.message]);
	},
);

export const axios = instance;
