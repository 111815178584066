import { QuickInsightCategory } from '@models/quick-insight-category';
import { useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToInstance } from 'class-transformer';

export const useQuickInsightCategories = () => {
	return useQuery({
		queryKey: ['quick-insight-categories'],
		queryFn: async () => {
			const url = `/quick-insights/categories`;

			return axios.get<Array<QuickInsightCategory>>(url).then(({ data }) => plainToInstance(QuickInsightCategory, data));
		},
	});
};
