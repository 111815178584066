import { QuickInsightCategory } from "@models/quick-insight-category";
import { useQuickInsightCategories } from "@utils/querys/quick-insights/use-quick-insight-categories";
import Select, { Props } from "react-select";

export const QuickInsightCategorySelect = (props: Props<QuickInsightCategory, false>) => {
	const { data: categories, isSuccess } = useQuickInsightCategories();

	return (
		isSuccess && (
			<Select getOptionLabel={(option) => option.name} isSearchable options={categories} placeholder="Select category" {...props} />
		)
	);
};
