import { User } from '@models/user';
import * as Sentry from '@sentry/react';

export const setSentryUser = (user: User | null = null) => {
	if (user) {
		Sentry.setUser({ uuid: user.uuid, name: user.name });
	} else {
		Sentry.setUser(null);
	}
};
