import { QuickInsightGroup } from '@models/quick-insight-group';
import { useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToInstance } from 'class-transformer';

export const useQuickInsightGroups = () => {
	return useQuery({
		queryKey: ['quick-insight-groups'],
		queryFn: async () => {
			return axios.get<Array<QuickInsightGroup>>(`/quick-insights/groups`).then(({ data }) => plainToInstance(QuickInsightGroup, data));
		},
	});
};
