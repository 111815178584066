import "reflect-metadata";
import "./main.css";

import "@configs/sentry";
import { Providers } from "@providers/providers";
import { RouterProvider } from "@tanstack/react-router";
import { createRouter } from "@utils/helpers/create-router";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

const router = createRouter();
const root = document.getElementById("root");

if (root) {
	createRoot(root).render(
		<StrictMode>
			<Providers>
				<RouterProvider router={router} />
			</Providers>
		</StrictMode>,
	);
}
