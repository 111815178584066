import { axios } from '@utils/clients/axios-client';
import { useMutation } from '@utils/helpers/use-mutation';

export const useDeleteReminder = () => {
	return useMutation({
		mutationFn: async (uuid: string) => {
			return axios.delete(`/reminders/${uuid}`);
		},
	});
};
