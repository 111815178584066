import { useSession } from "@providers/session/session.provider";
import { createFileRoute, Navigate } from "@tanstack/react-router";

const Index = () => {
	const { isExpired } = useSession();
	const to = isExpired ? "/login" : "/dashboard/welcome";

	return <Navigate replace={true} to={to} />;
};

export const Route = createFileRoute("/")({
	component: Index,
});
