import { Type } from 'class-transformer';
import { BaseModel } from './base-model';
import { UserRole } from './user-role';

export class User extends BaseModel {
	public name: string;
	public email: string;

	@Type(() => UserRole)
	public role: UserRole;

	public avatarBlurhash: string;

	public get avatar(): string {
		return `${import.meta.env.VITE_IMAGES_CDN_ENDPOINT}/users/avatars/${this.uuid}.webp`;
	}
}
