import { Pagination } from '@models/pagination';
import { Reminder } from '@models/reminder';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToClassFromExist } from 'class-transformer';

export interface FilterRemindersDTO {
	page: number;
	search?: string;
}

export const useReminders = (params: FilterRemindersDTO) => {
	const { page, search } = params;

	return useQuery({
		queryKey: ['reminders', page, search],
		placeholderData: keepPreviousData,
		queryFn: async () => {
			const params = { page, search };

			return axios.get<Array<Reminder>>(`/reminders`, { params }).then(({ data }) => {
				return plainToClassFromExist(new Pagination<Reminder>(Reminder), data);
			});
		},
	});
};
