import { PageTitle } from "@components/page-title/page-title.component";
import { createFileRoute, useParams } from "@tanstack/react-router";
import { useUser } from "@utils/querys/users/use-user";

const User = () => {
	const { uuid } = useParams({ from: Route.fullPath });

	const { data: user, isSuccess } = useUser(uuid!);

	return (
		isSuccess && (
			<div className="text-white">
				<PageTitle title={user.name} />
				<p>{user.role.name}</p>
			</div>
		)
	);
};

export const Route = createFileRoute("/dashboard/users/$uuid/")({
	component: User,
});
