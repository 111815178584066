import { Author } from '@models/author';
import { Quote } from '@models/quote';
import { QuoteCategory } from '@models/quote-category';
import { useQueryClient } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { useMutation } from '@utils/helpers/use-mutation';
import { plainToInstance } from 'class-transformer';

export interface CreateQuoteDTO {
	text: string;
	author: Author | null;
	category: QuoteCategory;
}

export const useCreateQuote = () => {
	const client = useQueryClient();

	return useMutation({
		onSuccess: () => {
			client.removeQueries({ queryKey: ['quotes'] });
		},
		mutationFn: async (payload: CreateQuoteDTO) => {
			return axios.post<Quote>('/quotes', payload).then(({ data }) => plainToInstance(Quote, data));
		},
	});
};
