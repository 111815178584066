import { User } from '@models/user';
import { UserRole } from '@models/user-role';
import { axios } from '@utils/clients/axios-client';
import { useMutation } from '@utils/helpers/use-mutation';
import { plainToInstance } from 'class-transformer';

export interface CreateUserDTO {
	name: string;
	email: string;
	role: UserRole;
}

export const useCreateUser = () => {
	return useMutation({
		mutationFn: async (payload: CreateUserDTO) => {
			return axios.post<User>('/users', payload).then(({ data }) => plainToInstance(User, data));
		},
	});
};
