import { cn } from "@utils/helpers/class-name";
import { useState } from "react";
import { InputProps } from "react-html-props";
import { MdArrowForward } from "react-icons/md";

interface SearchInputProps extends InputProps {
	onSearch: (value: string) => void;
}

export const SearchInput = ({ onSearch, ...props }: SearchInputProps) => {
	const [value, setValue] = useState("");

	const submit = () => {
		if (value) {
			onSearch(value);
			setValue("");
		}
	};

	const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			submit();
		}
	};

	return (
		<div className="flex h-11 w-full max-w-80 items-center justify-between rounded-full bg-white bg-opacity-20 py-1 pl-5 pr-1">
			<input
				className="w-full bg-transparent pr-3 text-xs font-semibold text-white placeholder-white focus:outline-none"
				onChange={(e) => setValue(e.target.value.trim())}
				onKeyUp={onKeyUp}
				value={value}
				{...props}
			/>
			<button
				className={cn("flex h-8 w-8 cursor-pointer items-center justify-center rounded-full", value ? "bg-blue-active" : "bg-gray-500")}
				disabled={!value}
				onClick={submit}
				type="button">
				<MdArrowForward size={25} />
			</button>
		</div>
	);
};
