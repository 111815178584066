import { QueryClient, useMutation as useMutationLib, UseMutationOptions } from '@tanstack/react-query';

export const useMutation = <TData = unknown, TError = Array<string>, TVariables = void, TContext = unknown>(
	options: UseMutationOptions<TData, TError, TVariables, TContext>,
	queryClient?: QueryClient,
) => {
	const mutation = useMutationLib<TData, TError, TVariables, TContext>(options, queryClient);

	return { ...mutation, error: undefined, errors: mutation.error || [] };
};
