import { UserRole } from '@models/user-role';
import { useQuery } from '@tanstack/react-query';
import { axios } from '@utils/clients/axios-client';
import { plainToInstance } from 'class-transformer';

export const useUsersRoles = () => {
	return useQuery({
		queryKey: ['users-roles'],
		queryFn: async () => {
			return axios.get<Array<UserRole>>(`/users/roles`).then(({ data }) => plainToInstance(UserRole, data));
		},
	});
};
