interface ErrorStateProps {
	errors: Array<string>;
}

export const ErrorState = ({ errors }: ErrorStateProps) => {
	return (
		<div className="px-4 pt-2 text-xs text-red-error">
			<ul>
				{errors.map((error, index) => (
					<li key={index}>{error}</li>
				))}
			</ul>
		</div>
	);
};
