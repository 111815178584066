import { Link } from "@tanstack/react-router";
import { FaPlus } from "react-icons/fa6";

interface FloatingActionButtonProps {
	to: string;
}

export const FloatingActionButton = ({ to }: FloatingActionButtonProps) => {
	return (
		<Link
			className="fixed bottom-28 right-14 z-10 flex h-16 w-16 items-center justify-center rounded-full bg-blue-primary text-white"
			to={to}>
			<FaPlus size="22" />
		</Link>
	);
};
