export const SECURE_STORE_TOKEN_KEY = 'token';

export const getTokenFromStorage = () => {
	return localStorage.getItem(SECURE_STORE_TOKEN_KEY) || '';
};

export const setTokenInStorage = (token?: string) => {
	return localStorage.setItem(SECURE_STORE_TOKEN_KEY, token || '');
};

export const clearStorage = () => {
	localStorage.clear();
	window.location.reload();
};
